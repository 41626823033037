::-webkit-scrollbar {
	width: 6px;
	height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 100vh;
	background: #fffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #D3D3D3;
	border-radius: 100vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a0aec0;
}

.h-screen-body {
	height: calc(100vh - 2.5rem) !important;
}

.h-sitebox {
	height: 25rem !important;
}

.ReactTable .rt-tbody .rt-tr-group {
	border: none;
	line-height: 30px;
	vertical-align: middle;
}

.blueTextColor {
	color: #002366;
}

/* Has to be removed while deploying*/
.ReactTable .rt-thead.-header {
	box-shadow: none;
	border-bottom: 3px solid lightgray;
	color: #6b7280;
}
.ReactTable .rt-tbody .rt-tr-group {
	border: none;
	color: #10233F
}
h1, h2, h3, h4, h5 {
	font-family: 'DINNextLTPro-Bold' !important;
	font-weight: 500;
	color: #707070 !important;
}
a {
	color: #707070;
	text-decoration: none;
}
.Select.is-disabled {
	color: #707070 !important;
}
.sideBar {
	font-family: 'HelveticaNeue' !important;
	font-weight: 900;
}
.rc-time-picker-input {
	font-size: medium !important;
	border-radius: 8px !important;
	padding: 2px !important;
	height: 40px !important;
  text-align: center;
}
.rc-time-picker-clear-icon {
	display: none !important;
padding: 2px;
}

.blueText {
	color: #002366;
}
.barChart {
	height: 200px;
	width: 100%;
}
.highcharts-scrollbar-rifles, .highcharts-scrollbar-arrow {
	display: none !important;
}
/*For print*/
@media print {
	@page {
		size: landscape;
	}

	body * {
		visibility: hidden;
	}

	.section-not-to-print, .section-not-to-print * {
		visibility: hidden;
	}


	.section-to-print, .section-to-print * {
		visibility: visible;
	}

	.section-to-print {
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
	}
}