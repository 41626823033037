input::-webkit-input-placeholder {
	color: blue;
	font-weight: bold;
}

input::-moz-placeholder {
	color: blue;
	font-weight: bold;
}

input:-ms-input-placeholder {
	color: blue;
	font-weight: bold;
}

input:-o-input-placeholder {
	color: blue;
	font-weight: bold;
}