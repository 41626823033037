input.samplePlan:-webkit-autofill,
input.samplePlan:-webkit-autofill:hover,
input.samplePlan :-webkit-autofill:focus,
input.samplePlan:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #fff inset !important;
	-webkit-text-fill-color: #002366;
}
.samplePlanTextColor {
color: #646464;
}
.selectBorder {
border-radius: 2px;
}